// This file was automatically generated from concierge.directory.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Concierge == 'undefined') { Nightloop.Templates.Concierge = {}; }
if (typeof Nightloop.Templates.Concierge.Directory == 'undefined') { Nightloop.Templates.Concierge.Directory = {}; }


Nightloop.Templates.Concierge.Directory.Search = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Concierge.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Concierge.Directory.SearchBit', full_width: true}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.Directory.SearchBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-directory"><div id="search-container"><div id="left-filters" class="float-left"><div><div id="header">Search Venue Directory</div><div id="search-box"><form id="search-form" class="ajaxify" method="GET" action="', soy.$$escapeHtml(opt_data.concierge.base_url), '/directory"><input id="dv-input" type="hidden" name="dv" value="', soy.$$escapeHtml(opt_data.content.dv), '"/><div class="search-input"><input id="search-query-input" type="text" name="search_query" placeholder="Name or keywords (optional)" value="', soy.$$escapeHtml(opt_data.content.search_query), '"/></div><div class="search-input"><select name=\'venue_class\'><option ', (opt_data.content.venue_class == 'DINING') ? 'selected' : '', ' value="DINING">Dining</option><option ', (opt_data.content.venue_class == 'NIGHTLIFE') ? 'selected' : '', ' value="NIGHTLIFE">Nightlife</option></select></div><div class="search-input"><select name=\'municipality\'>');
  var muniList5301 = opt_data.content.municipalities;
  var muniListLen5301 = muniList5301.length;
  for (var muniIndex5301 = 0; muniIndex5301 < muniListLen5301; muniIndex5301++) {
    var muniData5301 = muniList5301[muniIndex5301];
    output.append('<option ', (opt_data.content.muni_id == muniData5301[0]) ? 'selected' : '', ' value="', soy.$$escapeHtml(muniData5301[0]), '">', soy.$$escapeHtml(muniData5301[1]), ', ', soy.$$escapeHtml(muniData5301[2]), '</option>');
  }
  output.append('</select></div><div id="search-submit" class="search-button nightlife">SEARCH</div></form></div>');
  Nightloop.Base.VenueTypeFilters({num_gdc_venues: opt_data.content.num_gdc_venues, num_instant_book_venues: opt_data.content.num_instant_book_venues, show_gdc_venues: opt_data.concierge.show_gdc_venues}, output);
  Nightloop.Base.NeighborhoodFilter({neighborhoods: opt_data.content.neighborhoods}, output);
  Nightloop.Base.CuisineFilter({venue_class: opt_data.content.venue_class, cuisines: opt_data.content.cuisines}, output);
  Nightloop.Base.CategoryFilter({venue_class: opt_data.content.venue_class, categories: opt_data.content.categories}, output);
  Nightloop.Base.AtmosphereFilter({atmospheres: opt_data.content.atmospheres}, output);
  output.append('</div></div><div id="search-results" class="float-left"><div id="top-panel"><div class="matches float-left">', soy.$$escapeHtml(opt_data.content.search_numavail), ' VENUE', (opt_data.content.search_results.length > 1 || opt_data.content.search_results.length == 0) ? 'S' : '', ' FOUND</div><div class="view-mode float-right"><div id="list-view-link" class="float-left active">&nbsp;</div><div id="grid-view-link" class="float-left">&nbsp;</div><div class="float-end"></div></div><div class="float-end"></div></div><div id="results"><div id="overlay-container" class="no-display"><div id="overlay"><!--- <img src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/spinner.gif" alt="Loading..." /> ----></div></div><div id="list-view-results">');
  var venueList5338 = opt_data.content.search_results;
  var venueListLen5338 = venueList5338.length;
  if (venueListLen5338 > 0) {
    for (var venueIndex5338 = 0; venueIndex5338 < venueListLen5338; venueIndex5338++) {
      var venueData5338 = venueList5338[venueIndex5338];
      Nightloop.Templates.Concierge.Directory.VenueSearchResultList({venue_name: venueData5338.name, venue_neighborhood: venueData5338.neighborhood.name, venue_blurb: venueData5338.detail_blurb, venue_id: venueData5338.url_key_or_id, neighborhood_id: venueData5338.neighborhood.id, has_perks: venueData5338.has_perks, cutoff_time_display: venueData5338.cutoff_time_display, venue: venueData5338, reward: venueData5338.rewards_display, concierge: opt_data.concierge, subscribed: venueData5338.subscribed, search_query: opt_data.content.search_query, is_gdc_venue: venueData5338.is_global_dining_collection, is_instant_book: venueData5338.use_full_dining_backend, MEDIA_URL: opt_data.MEDIA_URL}, output);
    }
  } else {
    output.append('<div class="no-results">There are no results matching your search criteria</div>');
  }
  output.append('</div><div id="grid-view-results" class="no-display">');
  var venueList5359 = opt_data.content.search_results;
  var venueListLen5359 = venueList5359.length;
  if (venueListLen5359 > 0) {
    for (var venueIndex5359 = 0; venueIndex5359 < venueListLen5359; venueIndex5359++) {
      var venueData5359 = venueList5359[venueIndex5359];
      Nightloop.Templates.Concierge.Directory.VenueSearchResult({venue_photos: venueData5359.photos, venue_name: venueData5359.name, venue_neighborhood: venueData5359.neighborhood.name, venue_blurb: venueData5359.detail_blurb, venue_id: venueData5359.url_key_or_id, neighborhood_id: venueData5359.neighborhood.id, has_perks: venueData5359.has_perks, cutoff_time_display: venueData5359.cutoff_time_display, venue: venueData5359, reward: venueData5359.rewards_display, concierge: opt_data.concierge, subscribed: venueData5359.subscribed, search_query: opt_data.content.search_query, is_gdc_venue: venueData5359.is_global_dining_collection, is_instant_book: venueData5359.use_full_dining_backend, MEDIA_URL: opt_data.MEDIA_URL}, output);
    }
  } else {
    output.append('<div class="no-results">There are no results matching your search criteria</div>');
  }
  output.append('<div class="float-end"></div></div></div></div><div class="float-end"></div></div><script type="text/javascript">$(document).ready(function() {Pmp.Concierge.Directory.Search.initialize( \'', soy.$$escapeHtml(opt_data.concierge.base_url), '\', "', soy.$$escapeHtml(opt_data.concierge.id), '",', soy.$$escapeHtml(opt_data.content.search_results.length), ', "', soy.$$escapeHtml(opt_data.concierge.locale), '", "', soy.$$escapeHtml(opt_data.content.venue_class), '", "', soy.$$escapeHtml(opt_data.MEDIA_URL), '", "', soy.$$escapeHtml(opt_data.content.muni_today_date), '", "', soy.$$escapeHtml(opt_data.content.date_formatted_default), '" );', (opt_data.content.dv == 'g') ? 'Pmp.Concierge.Common.SearchPage._showGridView();' : '', '$(\'.venue-subscription\').tooltip({hide: false, position: {my: \'center bottom\', at: \'center top-10\', \'collision\': \'none\'}, tooltipClass: \'venue-subscription-tooltip\'});});<\/script></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.Directory.VenueSearchResult = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="result-box float-left" venue_id="', soy.$$escapeHtml(opt_data.venue_id), '" venue_name="', opt_data.venue_name, '" neighborhood_id="', soy.$$escapeHtml(opt_data.neighborhood_id), '" cuisine_keywords="', soy.$$escapeHtml(opt_data.venue.cuisine_keywords_display), '" category_keywords="', soy.$$escapeHtml(opt_data.venue.category_keywords_display), '" atmosphere_keywords="', soy.$$escapeHtml(opt_data.venue.atmosphere_keywords_display), '" subscribed="', soy.$$escapeHtml(opt_data.venue.subscribed), '" is_dining="', (opt_data.venue.is_dining_class) ? 't' : 'f', '" is_gdc_venue="', soy.$$escapeHtml(opt_data.is_gdc_venue), '" is_instant_book="', soy.$$escapeHtml(opt_data.is_instant_book), '"><div class="photo venue-link">');
  if (opt_data.venue_photos) {
    var photoList5430 = opt_data.venue_photos;
    var photoListLen5430 = photoList5430.length;
    for (var photoIndex5430 = 0; photoIndex5430 < photoListLen5430; photoIndex5430++) {
      var photoData5430 = photoList5430[photoIndex5430];
      output.append((photoIndex5430 == 0) ? '<img src="/.h/download/' + soy.$$escapeHtml(photoData5430.medium) + '" width="339" />' : '');
    }
  } else {
    output.append('&nbsp;');
  }
  output.append('</div><div class="description"><a href="', soy.$$escapeHtml(opt_data.concierge.base_url), '/venue/', soy.$$escapeHtml(opt_data.venue_id), '?', soy.$$escapeHtml(opt_data.search_query), '" class="venue-name venue-link" target="_blank">', opt_data.venue_name, '</a>');
  if (opt_data.venue.is_dining_class) {
    Nightloop.Templates.Concierge.Directory.SubscriptionSelection({venue_id: opt_data.venue_id, is_subscribed: false}, output);
  }
  output.append((opt_data.is_gdc_venue && opt_data.concierge.show_gdc_venues) ? '<span class="is-gdc-venue"><img class="gdc-icon" src="' + soy.$$escapeHtml(opt_data.MEDIA_URL) + 'images/widget/icon-amex.png"/></span>' : '', '<div class="venue-tags"><div class="ellipsis">', opt_data.venue_neighborhood, ' | ', (opt_data.venue.is_dining_class) ? opt_data.venue.cuisine_keywords_display : opt_data.venue.category_keywords_display, '</div></div><div class="venue-tags-more ellipsis">', opt_data.venue.atmosphere_keywords_display, '</div>', (opt_data.cutoff_time_display) ? '<div class="venue-cutoff">Cutoff: ' + soy.$$escapeHtml(opt_data.cutoff_time_display) + '</div>' : '', '<div>', (opt_data.has_perks) ? '<div class="venue-perk float-left">PERK</div>' : '', (opt_data.reward) ? '<div class="venue-rewards float-left">' + soy.$$escapeHtml(opt_data.reward) + '</div>' : '', '<div class="float-end"></div></div></div><div class="booking-options">', (opt_data.concierge.can_custom_dining_request) ? '<div><a class="custom-reservation-popup-link custom-request-btn" href="javascript:void(0);" venue_name="' + opt_data.venue_name + '" venue_id="' + soy.$$escapeHtml(opt_data.venue_id) + '">request</a></div>' : '<div class=\'requests-disabled\'>&nbsp;</div>', '</div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.Directory.SubscriptionSelection = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<a href="javascript:void(0);" data-unsub-title="Click to Subscribe" data-sub-title="Click to Unsubscribe" title="', (opt_data.is_subscribed) ? 'Click to Unsubscribe' : 'Click to Subscribe', '" class="venue-subscription ', (opt_data.is_subscribed) ? 'subscribed' : 'unsubscribed', '"', (opt_data.venue_id) ? 'data-id="' + soy.$$escapeHtml(opt_data.venue_id) + '"' : '', '></a>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.Directory.VenueSearchResultList = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="result-box list-view" venue_id="', soy.$$escapeHtml(opt_data.venue_id), '" venue_name="', opt_data.venue_name, '" neighborhood_id="', soy.$$escapeHtml(opt_data.neighborhood_id), '" cuisine_keywords="', soy.$$escapeHtml(opt_data.venue.cuisine_keywords_display), '" category_keywords="', soy.$$escapeHtml(opt_data.venue.category_keywords_display), '" atmosphere_keywords="', soy.$$escapeHtml(opt_data.venue.atmosphere_keywords_display), '" subscribed="', soy.$$escapeHtml(opt_data.subscribed), '" is_dining="', (opt_data.venue.is_dining_class) ? 't' : 'f', '" is_gdc_venue="', soy.$$escapeHtml(opt_data.is_gdc_venue), '" is_instant_book="', soy.$$escapeHtml(opt_data.is_instant_book), '"><div class="description float-left"><a href="', soy.$$escapeHtml(opt_data.concierge.base_url), '/venue/', soy.$$escapeHtml(opt_data.venue_id), '?', soy.$$escapeHtml(opt_data.search_query), '" class="venue-name venue-link" target="_blank">', opt_data.venue_name, '</a>');
  if (opt_data.venue.is_dining_class) {
    Nightloop.Templates.Concierge.Directory.SubscriptionSelection({venue_id: opt_data.venue_id, is_subscribed: opt_data.subscribed}, output);
  }
  output.append((opt_data.is_gdc_venue && opt_data.concierge.show_gdc_venues) ? '<span class="is-gdc-venue"><img class="gdc-icon" src="' + soy.$$escapeHtml(opt_data.MEDIA_URL) + 'images/widget/icon-amex.png"/></span>' : '', '<div class="venue-tags"><div class="ellipsis">', opt_data.venue_neighborhood, ' | ', (opt_data.venue.is_dining_class) ? opt_data.venue.cuisine_keywords_display : opt_data.venue.category_keywords_display, '</div></div><div class="venue-tags-more ellipsis">', opt_data.venue.atmosphere_keywords_display, '</div>', (opt_data.cutoff_time_display) ? '<div class="venue-cutoff">Cutoff: ' + soy.$$escapeHtml(opt_data.cutoff_time_display) + '</div>' : '', '<div>', (opt_data.has_perks) ? '<div class="venue-perk float-left">PERK</div>' : '', (opt_data.reward) ? '<div class="venue-rewards float-left">' + soy.$$escapeHtml(opt_data.reward) + '</div>' : '', '<div class="float-end"></div></div></div><div class="booking-options float-right">', (opt_data.concierge.can_custom_dining_request) ? '<div><a class="custom-reservation-popup-link custom-request-btn" href="javascript:void(0);" venue_name="' + opt_data.venue_name + '" venue_id="' + soy.$$escapeHtml(opt_data.venue_id) + '">request</a></div>' : '<div class=\'requests-disabled\'>&nbsp;</div>', '</div><div class="float-end"></div></div>');
  return opt_sb ? '' : output.toString();
};
